#ant_inputs
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector,
#ant_inputs .ant-input,
#ant_inputs .ant-select-selector,
#ant_inputs .ant-picker,
#ant_inputs .ant-select-selection-search-input,
#ant_inputs .ant-input-number-input {
  height: 40px !important;
  padding: 5px 11px !important;
  box-shadow: none;
}
#ant_inputs textarea.ant-input {
  height: auto !important;
}
#pickup-noti textarea.textareapick {
  height: 150px !important;
}
#ant_inputs .css-yk16xz-control,
#ant_inputs .css-1pahdxg-control {
  /* height: 40px !important; */
  border-radius: 2px;
  outline: none !important;
  box-shadow: none !important;
  text-transform: capitalize;
}
#ant_inputs .search-product .css-1pahdxg-control,
#ant_inputs .search-product .css-yk16xz-control {
  height: auto !important;
  padding: 1px;
  text-transform: capitalize;
}

#ant_inputs .css-yk16xz-control:hover,
#ant_inputs .css-1pahdxg-control:hover {
  border-color: #40a9ff;
  text-transform: capitalize;
}

#target_crops .ant-select-selector {
  height: auto !important;
  padding: 0px 10px !important;
}
#ant_inputs .ant-select-selection-search-input {
  height: 40px !important;
  padding: 0px !important;
}
#ant_inputs .ant-select-multiple .ant-select-selection-item {
  padding-inline-start: 10px !important;
  text-transform: uppercase;
  color: #fff;
  font-weight: 500;
  background: #5e6277 !important;
  border: 1px solid #5e6277 !important;
}

#ant_inputs .ant-select-selection-item-remove .anticon.anticon-close svg {
  width: 15px;
  height: 12px;
  fill: wheat;
}
.ant-table-thead > tr > th {
  color: #fff !important;
  background: #5e6277 !important;
}
.decline .ant-modal-body {
  padding: 10px !important;
}
.ant-form label {
  font-size: 13px !important;
}


/************ANTD MOdal Start*********/
.ant-modal-header {
  padding: 22px 24px !important;
  background-color: #5E6278 !important;
  border-radius: 0.42rem 0.42rem 0 0 !important;
}
.ant-modal-content {
  border-radius: 0.42rem !important;
}
.ant-modal-title {
  color: #fff !important;
}
.ant-modal-footer {
  text-align: center !important;
  border-top: none !important;
}
.ant-modal-footer {
  padding: 10px 16px 20px !important;
}
.ant-modal-close {
  color: #fff !important;
}
.ant-modal-footer button.ant-btn.ant-btn-default {
  display: none;
}
.ant-modal-footer button.ant-btn.ant-btn-primary {
  color: #FFFFFF;
  background-color: #04AA77 !important;
  padding: 0.65rem 1rem;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 0.42rem;
  height: auto;
  border: none !important;
}
/************ANTD MOdal END*********/